import { HomePage } from '@types'
import { GetStaticProps } from 'next'
import React from 'react'
import { Box } from 'theme-ui'
import HeroCarousel from '../components/HeroCarousel'
import ModuleSections from '../components/ModuleSections'
import PageWrapper from '../components/PageWrapper'
import { fetchPage } from '../utils/fetchPage'
import { HOME } from '../queries/sanity/documents/home'
import {NEXT_REVALIDATE} from "../constants";

type Props = {
  page: HomePage
  preview?: boolean
}

const Home = (props: Props) => {
  const { page, preview } = props

  return (
    <PageWrapper headerColor="white" preview={preview}>
      <Box>
        {page?.heroCarousel?.slides && (
          <HeroCarousel slides={page.heroCarousel.slides} />
        )}

        {/* Module sections */}
        {page?.moduleSections && (
          <ModuleSections hideWedge moduleSections={page.moduleSections} />
        )}
      </Box>
    </PageWrapper>
  )
}

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  const sanityResult = await fetchPage({
    filter: `_type == 'home'`,
    preview,
    projection: HOME,
  })

  return {
    notFound: !sanityResult,
    props: {
      page: sanityResult,
      preview: !!preview,
    },
    revalidate: NEXT_REVALIDATE, // seconds
  }
}

export default Home
